<template>
    <div class="iframe_wrapper_1">
        <iframe  frameborder="0"  scrolling="no" class="huixin_iframe" id="courseIframe" height="960" :src="iframeSrc" allowfullscreen="true"></iframe>
    </div>
</template>
<script>
export default{
    name: 'coursedetail',
    data(){
        return{}
    },
    computed: {
        iframeSrc: function() {
            let classId = this.$route.params.id
            return `https://huixinic.class.gaoxiaobang.com/huixin/class/${classId}/announcement`
        }
    },
    created(){

    },
    mounted(){

    },
    methods: {

    }
}
</script>
